<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">我的工单</h1>
    </div>
    <div slot="extra">
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="处理状态">
              <a-radio-group @change="selectStatus" v-model="searchParams.status">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="UN_PROCESS">待处理</a-radio-button>
                <a-radio-button value="PROCESSED">已处理</a-radio-button>
                <a-radio-button value="FINISHED">已完成</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="工单名称">
              <a-input v-model="searchParams.title" placeholder="请输入工单名称" allowClear/>
            </a-form-item>
            <!--<a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>-->
            <a-form-item label="工单来源">
              <a-select allowClear showSearch
                        v-model="searchParams.ticketSource"
                        placeholder="请选择工单来源"
                        @change="search">
                <a-select-option
                  v-for="(item, index) in tickSourceList"
                  :key="index"
                  :value="item.value"
                  :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-row :gutter="12">
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="总工单"
                :value="statisticsData.totalNumbers"
                :valueStyle="valueStyle"
                style="text-align: center;"
              >
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="已完成"
                :value="statisticsData.finishedNumbers"
                :valueStyle="valueStyle"
                style="text-align: center;"
              >
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="已处理"
                :value="statisticsData.processedNumbers"
                :valueStyle="valueStyle"
                style="text-align: center; color: #1890ff;"
              >
              </a-statistic>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card>
              <a-statistic
                title="待处理"
                :value="statisticsData.unProcessNumbers"
                :valueStyle="valueStyle"
                style="text-align: center; color: red;"
              >
              </a-statistic>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        bordered
        :scroll="{ x: 1400 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="ticket.ticketType" slot-scope="text, record">
          <span v-if="record.ticket.ticketType==='INSPECT'">巡检</span>
          <span v-if="record.ticket.ticketType==='REPORT'">上报</span>
          <span v-if="record.ticket.ticketType==='CAR'">车辆</span>
          <span v-if="record.ticket.ticketType==='OTHER'">其他</span>
        </template>
        <template slot="ticket.ticketSource" slot-scope="text, record">
          <span v-if="record.ticket.ticketSource==='MANUAL'">人工创建</span>
          <span v-if="record.ticket.ticketSource==='AUTO'">自动生成</span>
          <span v-if="record.ticket.ticketSource==='REPORT'">电话调度</span>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.status==='UN_PROCESS'" color="blue" >待处理</a-tag>
          <a-tag v-if="record.status==='PROCESSED'" color="#15da8e"> {{record.ticket.currentUser.name}}处理中</a-tag>
          <a-tag v-if="record.status==='FINISHED'" color="#1874CD">已完成</a-tag>
        </template>
        <template slot="startProcessTime" slot-scope="text, record">
          <span>{{record.startProcessTime | formatDate}}</span>
        </template>
        <template slot="endProcessTime" slot-scope="text, record">
          <span>{{record.endProcessTime | formatDate}}</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a v-if="record.status==='UN_PROCESS'&& record.user.id===currentUser.id"
             @click="handleClick(record.ticket.id)" @click.stop>处理</a>
          <span style="color: #babec1" v-else>已处理</span>
        </template>
      </a-table>
    </a-card>
    <!--处理Modal-->
    <a-modal title="工单处理" v-model="handleModal" width="560px">
      <handle ref="handleRef" @handleSuccess="handleSuccess" @handleError="handleError"></handle>
      <template slot="footer">
        <a-button :loading="handleLoading" @click="handleOk" type="primary" style="margin-right: 5px;">处理</a-button>
        <a-button @click="closeHandleModal">取消</a-button>
      </template>
    </a-modal>
    <a-drawer
      title="工单详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="550px"
      :visible="detailDrawer"
      :mask="false">
      <ticket-info-detail ref="viewRef"></ticket-info-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import { ticketInfoColumns, processStatusList, ticketTypeList, tickSourceList } from './common/common'
  import TicketInfoDetail from './Detail'
  import Handle from './Handle'
  import moment from 'moment'

  export default {
    name: 'MyTicketInfoList',
    components: { PageLayout, TicketInfoDetail, Handle },
    filters: {
      formatDate (date) {
        if (date) {
          return moment(date).format('YYYY-MM-DD HH:mm:ss')
        } else {
          return '~'
        }
      }
    },
    data () {
      return {
        searchUrl: SERVICE_URLS.ticket.myTicket.search,
        statisticUrl: SERVICE_URLS.ticket.myTicket.statistic,
        detailDrawer: false,
        handleModal: false,
        handleLoading: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        tableData: [],
        columns: ticketInfoColumns(),
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          status: 'UN_PROCESS',
          ticketSource: undefined
        },
        selectedRowKeys: [],
        columnsList: [],
        tableLoading: false,
        processStatus: processStatusList(),
        ticketTypeList: ticketTypeList(),
        tickSourceList: tickSourceList(),
        orgTree: [],
        handleId: '', //当前处理工单id，
        statisticsData: {},
        valueStyle: {}
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
      this.search()
    },
    created () {
      this.getOrgTree()
    },
    methods: {
      selectStatus () {
        this.search()
      },
      search () {
        this.$http(this, {
          url: this.searchUrl,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.number = data.body.number
            this.searchParams.page = data.body.number
          }
        })
        this.$http(this, {
          url: this.statisticUrl,
          noTips: true,
          loading: 'tableLoading',
          data: { userId: this.currentUser.id },
          success: (data) => {
            this.statisticsData = data.body
          }
        })
      },
      handleClick (id) {
        this.handleModal = true
        this.handleId = id
        console.log(id)
      },
      handleOk () {
        this.$nextTick(() => {
          this.$refs.handleRef.handle(this.handleId)
        })
      },
      closeHandleModal () {
        this.handleModal = false
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      detailClose () {
        this.detailDrawer = false
      },
      handleView (record) {
        return {
          on: {
            click: () => {
              this.detailDrawer = true
              this.$nextTick(() => {
                this.$refs.viewRef.loadData(record.ticket.id)
              })
            }
          }
        }
      },
      /*表格页数发生变化*/
      tableChange (pagination, filters, sorter) {
        // this.searchParams.size = pagination.pageSize
        // this.searchParams.number = pagination.current - 1
        this.setSearchParamsPage(pagination)
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.search()
      },
      setSearchParamsPage (pagination) {
        this.searchParams.page = pagination.current - 1
        this.searchParams.size = pagination.pageSize
      },
      handleSuccess () {
        this.search()
        this.handleModal = false
        this.$message.success('处理完成')
      },
      handleError () {
        this.handleModal = false
        this.$message.warning('处理失败')
      },
      resetForm () {
        this.searchParams = {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          status: 'UN_PROCESS'
        }
        this.search()
      }
    }
  }
</script>

<style scoped></style>